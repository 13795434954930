import { CSSProperties, useEffect } from 'react';

import { GoogleTagManager, sendGTMEvent } from '@next/third-parties/google';
import { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';

import { defaultTheme } from '@hultafors/snickers/helpers';
import { PageProps } from '@hultafors/snickers/types';
import './global.scss';

export { reportWebVitals } from '@hultafors/shared/helpers';

import './global.scss';

import '@hultafors/shared/style/main.scss';

const ApplicationProvider = dynamic(() =>
  import('@hultafors/snickers/context').then((mod) => mod.ApplicationProvider),
);

const Footer = dynamic(() =>
  import('@hultafors/snickers/components').then((mod) => mod.Footer),
);

const Fonts = dynamic(() =>
  import('@hultafors/snickers/components').then((mod) => mod.Fonts),
);

const Header = dynamic(() =>
  import('@hultafors/snickers/components').then((mod) => mod.Header),
);

const SharedThemeProvider = dynamic(() =>
  import('@hultafors/shared/context').then((mod) => mod.SharedThemeProvider),
);

const CustomApp = ({ Component, pageProps, router }: AppProps<PageProps>) => {
  const {
    global,
    allMenuItems,
    menuExtra,
    footer,
    settings,
    sizeGuide,
    workwearGuide,
    featureToggle,
  } = pageProps;

  const onRouteChangeComplete = (page: string) => {
    sendGTMEvent({ event: 'pageview', page });
  };

  useEffect(() => {
    if (router.events) {
      onRouteChangeComplete('/');
      router.events.on('routeChangeComplete', onRouteChangeComplete);
    }
    return () => {
      router.events.off('routeChangeComplete', onRouteChangeComplete);
    };
  }, [router.events]);

  const wrapperStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    minHeight: '100vh',
  };

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
      </Head>
      <SharedThemeProvider theme={defaultTheme}>
        <ApplicationProvider
          value={{
            allMenuItems,
            featureToggle,
            footer,
            global,
            menuExtra,
            settings,
            sizeGuide,
            workwearGuide,
          }}
        >
          <Fonts style={wrapperStyle}>
            {global && <Header />}
            <Component {...pageProps} />
            <GoogleTagManager gtmId="GTM-P5KFSP" />
            {footer && <Footer />}

            <div id="portal" />
          </Fonts>
        </ApplicationProvider>
      </SharedThemeProvider>
    </>
  );
};

export default CustomApp;
