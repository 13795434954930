import { DefaultTheme } from 'styled-components';

const colors = {
  black: {
    hover: '#232120',
    regular: '#000000',
  },
  footer: {
    background: 'var(--color-workwear-black)',
    hover: '#E9E8E8',
    text: 'var(--color-white)',
  },
  links: {
    hover: '#0000FF',
  },
  logoColor: '#111111',
  primary: {
    hover: '#B3B4BC',
    regular: '#232120',
  },
  quaternary: {
    hover: '#07E2B3',
    light: '#80FFE3',
    regular: '#00FFC8',
  },
  secondary: {
    hover: '#5F5FFF',
    regular: '#0000FF',
  },
  tertiary: {
    hover: '#EA7966',
    light: '#FCC0B7',
    regular: '#FA826E',
  },
  white: {
    regular: '#FFFFFF',
  },
};

const spacing = {
  block: '32px',
  large: '96px',
  medium: '48px',
  menuHeight: 'var(--header-height)',
  regular: '24px',
  small: '16px',
  xl: '192px',
  xsmall: '8px',
  xxsmall: '4px',
};

const fonts = {
  families: {
    regular: 'var(--font-fakt-pro)',
  },
  weights: {
    bold: 700,
    regular: 400,
  },
};

export const defaultTheme: DefaultTheme = {
  colors: colors,
  fonts: fonts,
  spacing: spacing,
};
